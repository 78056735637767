import pickBy from 'lodash/pickBy';
import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { ComponentRef, EditorSDK, SimpleSize } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import SinglePlanWidget from '../components/SinglePlanWidget/.component.json';
import { PLAN_COUNT_LIMIT, PRICING_PLANS_APP_DEF_ID } from '../constants';
import { ElementRole } from '../constants/elements';
import { toError } from './errors';

const WIDGET_REF_TYPE = 'platform.components.AppWidget';
const CONTAINER_REF_TYPE = 'wysiwyg.viewer.components.RefComponent';

export async function getContainerRef(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const [parentRef] = await editorSDK.document.components.getAncestors('', { componentRef })!;
  return parentRef;
}

export async function getCurrentPresetId(
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
): Promise<string | undefined> {
  const parentRef = await getContainerRef(editorSDK, componentRef);
  const preset = await editorSDK.document.application.appStudioWidgets.getPreset('', { componentRef: parentRef });
  return preset.layout;
}

export async function getRootWidget(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const ancestors = await editorSDK.components.getAncestors('', { componentRef });
  const ancestorTypes = await Promise.all(
    ancestors.map((ref) => editorSDK.components.getType('', { componentRef: ref })),
  );
  /*
    There might be more than one AppWidget type component
    in the hierarchy. `getAncestors` returns ancestors in order
    from closest to furthest, so `lastIndexOf` will find the root widget
  */
  const widgetIndex = ancestorTypes.lastIndexOf(WIDGET_REF_TYPE);
  return ancestors[widgetIndex];
}

export async function getPlanWidget(editorSDK: EditorSDK, rootWidgetContainer: ComponentRef) {
  const [controllerRef] = await editorSDK.components.getChildren('', { componentRef: rootWidgetContainer });
  const [planComponent] = await editorSDK.components.findAllByRole('', { controllerRef, role: ElementRole.PlanWidget });
  return planComponent;
}

export async function assignLatestPlanToWidgetIfNeeded(params: {
  widgetRef: ComponentRef;
  httpClient: IHttpClient;
  editorSDK: EditorSDK;
  currentPlanId?: string;
}): Promise<void> {
  const { widgetRef, httpClient, editorSDK, currentPlanId } = params;
  const { data } = await httpClient.request(listPublicPlans({ limit: PLAN_COUNT_LIMIT }));
  const isCurrentPlanValid = data.plans?.some((plan) => plan.id === currentPlanId);
  if (!isCurrentPlanValid) {
    const [newPlan] = data.plans ?? [];
    if (newPlan) {
      await editorSDK.document.application.appStudioWidgets.props.set('', {
        widgetRef,
        newProps: { planId: newPlan.id },
      });
    }
  }
}

export async function isSinglePlanWidgetRef(editorSDK: EditorSDK, refComponent: ComponentRef) {
  const data = (await editorSDK.components.data.get('', { componentRef: refComponent })) as
    | { widgetId: string; appDefinitionId: string }
    | undefined;
  return Boolean(data?.appDefinitionId === PRICING_PLANS_APP_DEF_ID && data?.widgetId === SinglePlanWidget.id);
}

export async function assertSinglePlanWidgetExists(editorSDK: EditorSDK, refComponent: ComponentRef) {
  const widget = await getPlanWidget(editorSDK, refComponent);
  if (!widget) {
    throw new Error('SinglePlanWidget does not exist');
  }
}

export async function openPlanForm(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  const rootWidget = await getRootWidget(editorSDK, componentRef);
  const { planId } = await editorSDK.application.appStudioWidgets.props.get('', { widgetRef: rootWidget });
  const pricingPlansUrl = planId ? `pricing-plans/edit/${planId}` : 'pricing-plans/new';
  await editorSDK.editor.openDashboardPanel('', { url: pricingPlansUrl, closeOtherPanels: true });
  try {
    await assignLatestPlanToWidgetIfNeeded({
      widgetRef: rootWidget,
      httpClient: flowAPI.httpClient,
      editorSDK,
      currentPlanId: planId as string | undefined,
    });
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'PLAN_FORM_CLOSED',
  });
}

export async function componentHasRole(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  role: ElementRole;
}): Promise<boolean> {
  const { editorSDK, componentRef, role } = params;
  const [connection] = await editorSDK.document.controllers.listConnections('', { componentRef });
  return connection?.role === role;
}

export async function getParentWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}): Promise<ComponentRef | null> {
  const { editorSDK, componentRef } = params;
  const ancestors = await editorSDK.document.components.getAncestors('', { componentRef });
  for (const ancestorRef of ancestors) {
    const type = await editorSDK.document.components.getType('', { componentRef: ancestorRef });
    if (type === WIDGET_REF_TYPE) {
      return ancestorRef;
    }
  }
  return null;
}

export async function findComponentByRole(params: {
  editorSDK: EditorSDK;
  controllerRef: ComponentRef;
  role: ElementRole;
}): Promise<ComponentRef | null> {
  const { editorSDK, controllerRef, role } = params;
  const [component] = await editorSDK.document.components.findAllByRole('', { controllerRef, role });
  return component ?? null;
}

export async function isWidgetRef(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const type = await editorSDK.document.components.getType('', { componentRef });
  return type === WIDGET_REF_TYPE;
}

export async function isContainerRef(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const type = await editorSDK.document.components.getType('', { componentRef });
  return type === CONTAINER_REF_TYPE;
}

export async function updateWidgetSizeClassic(params: {
  editorSDK: EditorSDK;
  containerRef: ComponentRef;
  width?: number;
  height?: number;
}) {
  const { editorSDK, containerRef, width, height } = params;
  const newSize = pickBy(
    {
      width,
      height,
    },
    (val) => val !== undefined,
  );
  return editorSDK.document.components.layout.update('', {
    componentRef: containerRef,
    layout: newSize,
  });
}

export async function updateWidgetSizeEditorX(params: {
  editorSDK: EditorSDK;
  containerRef: ComponentRef;
  width?: SimpleSize;
  height?: SimpleSize;
}) {
  const { editorSDK, containerRef, width, height } = params;
  const currentLayout = await editorSDK.document.responsiveLayout.get('', { componentRef: containerRef });
  const newSize = pickBy({ width, height }, (val) => val !== undefined);
  return editorSDK.document.responsiveLayout.update('', {
    componentRef: containerRef,
    responsiveLayout: {
      ...currentLayout,
      componentLayouts: [
        {
          ...currentLayout.componentLayouts[0],
          ...newSize,
        },
        ...currentLayout.componentLayouts.slice(1),
      ],
    },
  });
}
