import { Severity } from '@sentry/types';
import { EditorSDK } from '@wix/platform-editor-sdk';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import { toError } from './errors';

const appDefinitionId = pricingPlans.appDefinitionId;

interface CaptureExceptionOptions {
  print: boolean;
}

export function captureException(
  flowAPI: EditorScriptFlowAPI,
  err: unknown,
  options: Partial<CaptureExceptionOptions> = {},
) {
  if (typeof err === 'object' && err !== null && !(err instanceof Error)) {
    flowAPI.errorMonitor.addBreadcrumb({
      level: Severity.Error,
      message: 'Error object.',
      data: err,
    });
  }
  flowAPI.errorMonitor.captureException(toError(err));
  if (options.print) {
    console.error(err);
  }
}

const internalPages = ['Paywall', 'Thank You', 'Checkout'];

export async function setPageStates(sdk: EditorSDK) {
  console.log('>>> set page states');
  const pages = await sdk.pages.getApplicationPages('');
  const state = {
    internal: pages
      .filter((page) => page.id && page.tpaPageId && internalPages.includes(page.tpaPageId))
      .map((page) => ({ id: page.id! })),
  };
  console.log('>>> states', state);
  if (state.internal.length) {
    await sdk.pages.setState('', { state });
  }
}

export async function splitPagesMigration(sdk: EditorSDK) {
  await markPackagePickerManaged(sdk);

  console.log('>>> install pages');

  const pages = await sdk.pages.getApplicationPages('');
  const tpaPageIds = pages.map((page) => page.tpaPageId);

  if (!tpaPageIds.includes('Paywall')) {
    await installPage(sdk, 'Paywall');
  }

  if (!tpaPageIds.includes('Thank You')) {
    await installPage(sdk, 'Thank You');
  }

  if (!tpaPageIds.includes('Checkout')) {
    await installPage(sdk, 'Checkout');
  }
}

function installPage(sdk: EditorSDK, tpaPageId: string) {
  console.log('>>> install page', tpaPageId);
  return sdk.document.tpa.add.component('', {
    appDefinitionId,
    managingAppDefId: appDefinitionId,
    componentType: 'PAGE',
    page: {
      pageId: tpaPageId,
      isHidden: true,
      shouldNavigate: false,
    },
  });
}

async function markPackagePickerManaged(sdk: EditorSDK) {
  console.log('>>> mark package picker as managed');
  const pages = await sdk.pages.data.getAll('');
  for (const page of pages) {
    if (page.appDefinitionId === appDefinitionId && !page.managingAppDefId && page.id) {
      await sdk.pages.data.update('', { pageRef: { id: page.id }, data: { managingAppDefId: appDefinitionId } });
    }
  }
}
