import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../../constants';
import { ElementRole } from '../../constants/elements';
import { openPlanForm } from '../../utils/widget';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;
  builder.gfpp().set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID });

  builder.configureConnectedComponents(ElementRole.PerkText, (textBuilder) => {
    textBuilder.set({ displayName: t('blocks.label.benefit-text') });
    textBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
    textBuilder
      .gfpp()
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      .set('mainAction1', {
        label: t('blocks.benefits.text.design'),
        onClick: (e) =>
          editorSDK.editor.openNativeComponentPanel('', 'settings', { componentRef: e.detail.componentRef }),
      })
      .set('mainAction2', {
        label: t('blocks.benefits.text.edit'),
        onClick: (e) => openPlanForm({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(ElementRole.PerkIcon, (iconBuilder) => {
    iconBuilder.set({ displayName: t('blocks.label.benefit-icon') });
    iconBuilder
      .gfpp()
      .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
      // TODO: Unhide when panel modularization is supported for icon element
      .set('settings', { behavior: 'HIDE' })
      .set('connect', { behavior: 'HIDE' })
      .set('link', { behavior: 'HIDE' });
  });
};
